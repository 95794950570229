import { NavigationButton, Title, SearchBar } from "@components/common";
import { PlusIcon } from "@components/icons";
import styles from './GroupHeader.module.css';

interface Props {
    onSearch?: (search: string) => void;
}

const GroupHeader = ({ onSearch }: Props) => {

    const handleSearch = (search: string) => {
        if (onSearch) onSearch(search);
    }

    return (
        <div className={styles.headerContainer}>
            <Title className={styles.title}>Groups</Title>
            <div className={styles.actionContainer}>
                <SearchBar 
                    className={styles.searchBar} 
                    onChange={handleSearch}
                    onSearch={handleSearch}
                    placeholder="Search by group name"
                />
                <NavigationButton
                    startIcon={<PlusIcon/>}
                    pathTo="add"
                    className={styles.addGroupBtn}
                >
                    Add group
                </NavigationButton>
            </div>
        </div>
    ); 
};

export default GroupHeader;