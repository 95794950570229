import { getGetGroupsQueryKey, useGetGroup, useGetUsers, useEditGroup, getGetGroupQueryKey } from "@api/generated";
import { Title, Button, Modal, SelectField, Loader, EmptyState } from "@components/common";
import styles from './AddUserToGroup.module.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from "react";
import { getUserIcon } from "@utils/transformers";

interface AddUserToGroupForm {
    id: string;
}

const AddUserToGroup = () => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    const queryClient = useQueryClient();

    const { data: users } = useGetUsers();
    const { data: group, isLoading } = useGetGroup(groupId ?? "");
    const { mutate: editGroup, isPending } = useEditGroup({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetGroupsQueryKey() });
            await queryClient.invalidateQueries({ queryKey: getGetGroupQueryKey(groupId ?? "") });
            navigate(-1);
        },
    } });

    const methods = useForm<AddUserToGroupForm>({
        resolver: yupResolver(
            Yup.object({
                id: Yup.string().trim().required("Choose a user"),
            })
        ),
        defaultValues: { 
            id: '',
        },
        mode: 'onChange',
    });

    const onSubmit = (values: AddUserToGroupForm) => {
        if (groupId) editGroup({groupId, data: {add: [values.id]}});
    };

    const options = useMemo(() => {
        if (users && group) {
            return users
                .filter(x => !group.members.some(y => y.user_id === x.user_id))
                .map(x => {
                    const icon = getUserIcon(x.type);
                    return { 
                        value: x.user_id,
                        label: (<div className={styles.selectItem}><div className={styles.selectItemIcon}>{icon}</div>{x.name}</div>)
                    };
                });
        }
        return [];
    }, [users, group]);

    if (isLoading) {
        return (
            <Loader/>
        );
    }
    
    if (!group) {
        return (
            <Modal className={styles.card}>
                <EmptyState size="medium" title={"No group found with this id"}/>
            </Modal>
        );
    }

    return (
        <Modal className={styles.card}>
            <FormProvider {...methods}>
                <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                <Title underlined>Update Group</Title>
                    <div className={styles.formContent}>
                        <SelectField className={styles.selectInput} name="id" options={options} placeholder="Choose user"/>
                        <Button
                            disabled={isPending}
                            className={styles.submitBtn}
                            type="submit"
                            variant="contained"
                            >
                            Submit
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AddUserToGroup;