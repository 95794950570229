import { AddWalletRequestBodySchema, getGetWalletsQueryKey, useCreateWallet, useGetChains } from "@api/generated";
import { Title, Button, Modal, SelectField } from "@components/common";
import styles from './AddWallet.module.css';
import { TextField } from "@components/common";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate } from "react-router";
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from "react";
import { getChainIcon } from "@utils/transformers";

const AddWallet = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate: createWallet, isPending } = useCreateWallet({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetWalletsQueryKey() });
            navigate("/wallets");
        },
    } });

    const { data } = useGetChains();
    const chains = useMemo(() => {
        if (data) {
            return data?.map(x => {
                const icon = getChainIcon(x.name);
                return { 
                    value: x.chain_id,
                    label: (<div className={styles.selectItem}><div className={styles.selectItemIcon}>{icon}</div>{x.name}</div>)
                };
            });
        }
        return [];
    }, [data]);

    const methods = useForm<AddWalletRequestBodySchema>({
        resolver: yupResolver(
            Yup.object({
                name: Yup.string().trim().required("Name is required"),
                chain_id: Yup.string().required("Choose a chain"),
            })
        ),
        defaultValues: { 
            name: '', 
            chain_id: '',
        },
        mode: 'onChange',
        
    });

    const onSubmit = (values: AddWalletRequestBodySchema) => {
        createWallet({ data: values });
    };

    return (
        <Modal className={styles.card} parentRoute="/wallets">            
            <FormProvider {...methods}>
                <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                <Title underlined>Add Wallet</Title>
                    <div className={styles.formContent}>
                    <TextField variant='underlined' className={styles.textInput} placeholder="Name" name="name"/>
                    <SelectField className={styles.selectInput} name="chain_id" options={chains} placeholder="Choose chain"/>
                    <Button
                        disabled={isPending}
                        className={styles.submitBtn}
                        type="submit"
                        variant="contained"
                        >
                        Submit
                    </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AddWallet;