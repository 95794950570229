import { Item, Select } from '@components/common/select/Select';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';

export interface SelectorOption {
  value: string;
  label: ReactNode;
}

export interface SelectorProps {
  options: SelectorOption[];
  value?: string;
  placeholder?: string;
  containerClassName?: string;
  boxClassName?: string;
  btnClassName?: string;
  onSelect?: (value: string) => void;
  variant?: 'expand' | 'shadow';
}

export const Selector = ({
  options,
  value,
  onSelect,
  ...props
}: SelectorProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<SelectorOption | undefined>();

  const handleSelect = (option: SelectorOption) => {
    setSelectedOption(option);
    if (onSelect) onSelect(option.value);
    setOpen(false);
  }

  const otherOptions = useMemo(() => {
    return options.filter(option => option.value !== selectedOption?.value);
  }, [options, selectedOption]);

  useEffect(() => {
    if (value) {
      setSelectedOption(options.find(option => option.value === value));
    }
  }, [value])

  return (
    <Select
      {...props}
      selectedOption={selectedOption}
      open={open}
      toggleOpen={() => setOpen(prev =>!prev)}
    >
      {otherOptions.map(option => (
        <Item key={option.value} btnClassName={props.btnClassName} onSelect={() => handleSelect(option)}>
          {option.label}
        </Item>
      ))}
    </Select>
  );
};
