/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * WalletApi
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetAuthCallback200,
  GetAuthCallbackParams,
  Login200,
  LoginBody,
  Logout200,
  PostAuthCra200,
  PostAuthCraBody,
  RefreshAccessToken200,
  RefreshAccessTokenBody
} from '.././schemas'
import { customInstance } from '../../api';
import type { ErrorType } from '../../api';



/**
 * @summary Allows a user to log in to the api
 */
export const login = (
    loginBody: LoginBody,
 ) => {
      
      
      return customInstance<Login200>(
      {url: `/auth/login`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: loginBody
    },
      );
    }
  


export const getLoginMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: LoginBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: LoginBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, {data: LoginBody}> = (props) => {
          const {data} = props ?? {};

          return  login(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>
    export type LoginMutationBody = LoginBody
    export type LoginMutationError = ErrorType<void>

    /**
 * @summary Allows a user to log in to the api
 */
export const useLogin = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof login>>, TError,{data: LoginBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof login>>,
        TError,
        {data: LoginBody},
        TContext
      > => {

      const mutationOptions = getLoginMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Refresh the access token. This needs to be done before the access token expires.
 */
export const refreshAccessToken = (
    refreshAccessTokenBody: RefreshAccessTokenBody,
 ) => {
      
      
      return customInstance<RefreshAccessToken200>(
      {url: `/auth/refresh`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: refreshAccessTokenBody
    },
      );
    }
  


export const getRefreshAccessTokenMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshAccessToken>>, TError,{data: RefreshAccessTokenBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof refreshAccessToken>>, TError,{data: RefreshAccessTokenBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof refreshAccessToken>>, {data: RefreshAccessTokenBody}> = (props) => {
          const {data} = props ?? {};

          return  refreshAccessToken(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RefreshAccessTokenMutationResult = NonNullable<Awaited<ReturnType<typeof refreshAccessToken>>>
    export type RefreshAccessTokenMutationBody = RefreshAccessTokenBody
    export type RefreshAccessTokenMutationError = ErrorType<void>

    /**
 * @summary Refresh the access token. This needs to be done before the access token expires.
 */
export const useRefreshAccessToken = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof refreshAccessToken>>, TError,{data: RefreshAccessTokenBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof refreshAccessToken>>,
        TError,
        {data: RefreshAccessTokenBody},
        TContext
      > => {

      const mutationOptions = getRefreshAccessTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Logout of the current session. Removes the current access token.
 */
export const logout = (
    
 ) => {
      
      
      return customInstance<Logout200>(
      {url: `/auth/logout`, method: 'POST'
    },
      );
    }
  


export const getLogoutMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof logout>>, void> = () => {
          

          return  logout()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LogoutMutationResult = NonNullable<Awaited<ReturnType<typeof logout>>>
    
    export type LogoutMutationError = ErrorType<void>

    /**
 * @summary Logout of the current session. Removes the current access token.
 */
export const useLogout = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof logout>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof logout>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getLogoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Reply to challenge from login request
 */
export const postAuthCra = (
    postAuthCraBody: PostAuthCraBody,
 ) => {
      
      
      return customInstance<PostAuthCra200>(
      {url: `/auth/cra`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postAuthCraBody
    },
      );
    }
  


export const getPostAuthCraMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuthCra>>, TError,{data: PostAuthCraBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postAuthCra>>, TError,{data: PostAuthCraBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAuthCra>>, {data: PostAuthCraBody}> = (props) => {
          const {data} = props ?? {};

          return  postAuthCra(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostAuthCraMutationResult = NonNullable<Awaited<ReturnType<typeof postAuthCra>>>
    export type PostAuthCraMutationBody = PostAuthCraBody
    export type PostAuthCraMutationError = ErrorType<void>

    /**
 * @summary Reply to challenge from login request
 */
export const usePostAuthCra = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAuthCra>>, TError,{data: PostAuthCraBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postAuthCra>>,
        TError,
        {data: PostAuthCraBody},
        TContext
      > => {

      const mutationOptions = getPostAuthCraMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Callback handler for OIDC provider
 */
export const getAuthCallback = (
    params: GetAuthCallbackParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetAuthCallback200>(
      {url: `/auth/callback`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetAuthCallbackQueryKey = (params: GetAuthCallbackParams,) => {
    return [`/auth/callback`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAuthCallbackQueryOptions = <TData = Awaited<ReturnType<typeof getAuthCallback>>, TError = ErrorType<void>>(params: GetAuthCallbackParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthCallback>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuthCallbackQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthCallback>>> = ({ signal }) => getAuthCallback(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAuthCallback>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAuthCallbackQueryResult = NonNullable<Awaited<ReturnType<typeof getAuthCallback>>>
export type GetAuthCallbackQueryError = ErrorType<void>


export function useGetAuthCallback<TData = Awaited<ReturnType<typeof getAuthCallback>>, TError = ErrorType<void>>(
 params: GetAuthCallbackParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthCallback>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAuthCallback>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAuthCallback<TData = Awaited<ReturnType<typeof getAuthCallback>>, TError = ErrorType<void>>(
 params: GetAuthCallbackParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthCallback>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAuthCallback>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAuthCallback<TData = Awaited<ReturnType<typeof getAuthCallback>>, TError = ErrorType<void>>(
 params: GetAuthCallbackParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthCallback>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Callback handler for OIDC provider
 */

export function useGetAuthCallback<TData = Awaited<ReturnType<typeof getAuthCallback>>, TError = ErrorType<void>>(
 params: GetAuthCallbackParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthCallback>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAuthCallbackQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



