/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * WalletApi
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CreateGroupBody,
  DeleteGroup200,
  GroupSchema,
  GroupSimpleSchema,
  PatchGroupRequestBodySchema,
  PatchResponseBodySchema
} from '.././schemas'
import { customInstance } from '../../api';
import type { ErrorType } from '../../api';



/**
 * @summary Lists the groups
 */
export const getGroups = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<GroupSimpleSchema[]>(
      {url: `/groups`, method: 'GET', signal
    },
      );
    }
  

export const getGetGroupsQueryKey = () => {
    return [`/groups`] as const;
    }

    
export const getGetGroupsQueryOptions = <TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroups>>> = ({ signal }) => getGroups(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getGroups>>>
export type GetGroupsQueryError = ErrorType<void>


export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroups>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroups>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists the groups
 */

export function useGetGroups<TData = Awaited<ReturnType<typeof getGroups>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroups>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetGroupsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Creates a new empty group
 */
export const createGroup = (
    createGroupBody: CreateGroupBody,
 ) => {
      
      
      return customInstance<GroupSchema>(
      {url: `/groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createGroupBody
    },
      );
    }
  


export const getCreateGroupMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError,{data: CreateGroupBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError,{data: CreateGroupBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGroup>>, {data: CreateGroupBody}> = (props) => {
          const {data} = props ?? {};

          return  createGroup(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createGroup>>>
    export type CreateGroupMutationBody = CreateGroupBody
    export type CreateGroupMutationError = ErrorType<void>

    /**
 * @summary Creates a new empty group
 */
export const useCreateGroup = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGroup>>, TError,{data: CreateGroupBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createGroup>>,
        TError,
        {data: CreateGroupBody},
        TContext
      > => {

      const mutationOptions = getCreateGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Lists the users in the group
 */
export const getGroup = (
    groupId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GroupSchema>(
      {url: `/groups/${groupId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetGroupQueryKey = (groupId: string,) => {
    return [`/groups/${groupId}`] as const;
    }

    
export const getGetGroupQueryOptions = <TData = Awaited<ReturnType<typeof getGroup>>, TError = ErrorType<void>>(groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupQueryKey(groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroup>>> = ({ signal }) => getGroup(groupId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getGroup>>>
export type GetGroupQueryError = ErrorType<void>


export function useGetGroup<TData = Awaited<ReturnType<typeof getGroup>>, TError = ErrorType<void>>(
 groupId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroup>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroup<TData = Awaited<ReturnType<typeof getGroup>>, TError = ErrorType<void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroup>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroup<TData = Awaited<ReturnType<typeof getGroup>>, TError = ErrorType<void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists the users in the group
 */

export function useGetGroup<TData = Awaited<ReturnType<typeof getGroup>>, TError = ErrorType<void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroup>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetGroupQueryOptions(groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates which the group members
 */
export const editGroup = (
    groupId: string,
    patchGroupRequestBodySchema: PatchGroupRequestBodySchema,
 ) => {
      
      
      return customInstance<PatchResponseBodySchema>(
      {url: `/groups/${groupId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchGroupRequestBodySchema
    },
      );
    }
  


export const getEditGroupMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editGroup>>, TError,{groupId: string;data: PatchGroupRequestBodySchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof editGroup>>, TError,{groupId: string;data: PatchGroupRequestBodySchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editGroup>>, {groupId: string;data: PatchGroupRequestBodySchema}> = (props) => {
          const {groupId,data} = props ?? {};

          return  editGroup(groupId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditGroupMutationResult = NonNullable<Awaited<ReturnType<typeof editGroup>>>
    export type EditGroupMutationBody = PatchGroupRequestBodySchema
    export type EditGroupMutationError = ErrorType<void>

    /**
 * @summary Updates which the group members
 */
export const useEditGroup = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editGroup>>, TError,{groupId: string;data: PatchGroupRequestBodySchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof editGroup>>,
        TError,
        {groupId: string;data: PatchGroupRequestBodySchema},
        TContext
      > => {

      const mutationOptions = getEditGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes all users from a group and removes the group
 */
export const deleteGroup = (
    groupId: string,
 ) => {
      
      
      return customInstance<DeleteGroup200>(
      {url: `/groups/${groupId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteGroupMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError,{groupId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError,{groupId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGroup>>, {groupId: string}> = (props) => {
          const {groupId} = props ?? {};

          return  deleteGroup(groupId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGroup>>>
    
    export type DeleteGroupMutationError = ErrorType<void>

    /**
 * @summary Removes all users from a group and removes the group
 */
export const useDeleteGroup = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroup>>, TError,{groupId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteGroup>>,
        TError,
        {groupId: string},
        TContext
      > => {

      const mutationOptions = getDeleteGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    