import { useMemo } from "react";
import { User } from "../Users";
import { useGetUserWallets, WalletSchema } from "@api/generated";
import { IconButton, List, ListActionType, ListColumnType, ListItem, NavigationButton, Text } from "@components/common";
import styles from './UserData.module.css';
import { getChainIcon } from "@utils/transformers";
import { RedirectIcon } from "@components/icons";

interface UserDataProps {
    user?: User
}

interface Wallet extends WalletSchema {
    id: string;
}

const UserData = ({ user }: UserDataProps) => {
    
    const { data, isLoading } = useGetUserWallets(user?.id ?? "");

    const rows: Wallet[] = useMemo(() => {
        if (!data) return [];
        return data.map(row =>
            ({
                id: row.wallet_id,
                ...row
            }))
        }, [data]
    );

    const walletColumns: ListColumnType<Wallet>[] = 
    [
        {
            field: 'chain',
            headerName: '',
            width: '5%',
            textAlign: 'center',
            renderCell: (params) => (
                getChainIcon(params.data?.chain?.name ?? "")
            ),
        },
        {
            field: 'name',
            headerName: 'Name',
            width: '30%',
        },
        {
            field: 'address',
            headerName: 'Address',
            width: '65%',
            renderCell: (params) => <div className={styles.walletAddress}>{params.data?.address.slice(0, 10) + " ...... " + params.data?.address.slice(-10)}</div>
        },
    ];

    const userColumns: ListColumnType<User>[] = 
    [
        {
            width: '40%',
            field: 'user_id',
            headerName: 'User ID',
        },
        {
            width: '30%',
            field: 'name',
            headerName: 'Name',
        },
        {
            width: '30%',
            field: user?.type === 'TRADER' ? 'email' : 'public_key',
            headerName: user?.type === 'TRADER' ? 'Email' : 'Public key',
        },
    ];

    const actions: ListActionType<Wallet> =  
    ({
        width: '40px',
        textAlign: 'center',
        renderActionCell: (params) => (
            <NavigationButton variant="text" pathTo={`../wallets?wallet_id=${params.data?.wallet_id}`}><RedirectIcon/></NavigationButton>
        )
    });

    return (
        <div className={styles.userData}>
            <div>
                <Text className={styles.label}>Wallet Info</Text>
                <List
                    className={styles.list}
                    variant='contained'
                    columns={walletColumns}
                    data={rows}
                    actions={actions}
                    isLoading={isLoading}
                    rowHeight={40}
                    size='small'
                />
            </div>
            {user && 
                <div>
                    <Text className={styles.label}>User Info</Text>
                    <ListItem
                        height={58}
                        row={user}
                        columns={userColumns}
                        variant='outlined-free'
                        className={styles.userInfo}
                        type='secondary'
                    />
                </div>
            }
        </div>
    );
};

export default UserData;