import { Outlet, useLocation, matchPath } from "react-router";
import { Routing } from "../../RouteInterfaces";
import SideBar from "../SideBar/SideBar";
import styles from "./Layout.module.css";
import { useMemo } from "react";
import TopBar from "../TopBar/TopBar";

const Layout = ({ routes }: Routing) => {

    const location = useLocation();

    const backgroundElement = useMemo(() => {
        const currentRoute = routes.find(r => matchPath(r.path!, location.pathname));
        if (currentRoute && currentRoute.asModal) {
            const parentRoute = routes.find(r => `/${r.path}` === currentRoute.parent);
            return parentRoute?.element;

        }
        return undefined;
    }, [location]);

    return (
        <div className={styles.layoutContainer}>
            <SideBar routes={routes}/>
            <div className={styles.verticalContainer}>
            <TopBar/>
            <div className={styles.contentContainer}>
                {backgroundElement}
                <Outlet/>
            </div>
            </div>
        </div>
    );
}

export default Layout;