import { ChainSchema, useGetChains, useGetWallets, WalletSchema } from "@api/generated";
import { Loader, List, ListColumnType, BoldText, Title } from "@components/common";
import { useEffect, useMemo, useState } from "react";
import WalletHeader, { WalletQuery } from "./WalletHeader";
import { formatCurrency, getChainIcon } from "@utils/transformers";
import ChainData from "./ChainData/ChainData";
import { useSearchParams } from "react-router-dom";
import styles from "./Wallets.module.css";
import { randomNumberInRange } from "@utils/helpers";

export interface Chain extends ChainSchema {
    id: string;
    balance?: number;
}

export interface Wallet extends WalletSchema {
    id: string;
    balance?: number;
}

const Wallets = () => {

    const { data: chains, isLoading } = useGetChains();
    const { data: walletData } = useGetWallets();
    const [query, setQuery] = useState<WalletQuery>();
    const [_, setSearchParams] = useSearchParams();

    const wallets = useMemo(() => {
        return walletData?.map(x => ({ ...x, id: x.wallet_id}));
    }, [walletData]);

    const columns: ListColumnType<Chain>[] = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: '5%',
                textAlign: 'center',
                renderCell: (params) => (
                    <div className={styles.icon}>{getChainIcon(params.data?.name ?? "")}</div>
                ),
            },
            {
                field: 'name',
                headerName: '',
                width: '60%',
                renderCell: (params) => (<Title className={styles.chain} size="small">{params.data?.name}</Title>)
                
            },
            {
                field: 'balance',
                headerName: '',
                width: '30%',
                textAlign: 'right',
                renderCell: (params) => {
                    if (params.data?.balance) return (<Title className={styles.chain} size="small">{formatCurrency(params.data.balance)}</Title>);
                    return null;
                }
            },
        ], []
    );

    const filteredWallets = useMemo(() => {
            if (wallets && wallets.length > 0 && query && query.value.trim() !== "") {
                const result = wallets.filter(wallet => {
                    const value = wallet[query.field] as string;
                    if (!value) return true; //not a string so we cannot filter atm
                    return value.toLowerCase().includes(query.value.toLowerCase());
                });
                return result;
            }
            return wallets;
        }, [query, wallets]
    );

    const rows: Chain[] = useMemo(() => {
        if (!chains) return [];
        return chains.filter(chain => filteredWallets?.some(wallet => wallet.chain.chain_id === chain.chain_id)).map(row =>
            ({
                id: row.chain_id,
                ...row
            }))
        }, [chains, filteredWallets, wallets]
    );

    useEffect(() => {
        if (filteredWallets?.length === 1) {
            setSearchParams({wallet_id: filteredWallets[0].wallet_id})
        }
    }, [filteredWallets])

    return (
        <>
            {isLoading ? (
                <Loader />
                ) : (
                <List
                    className={styles.list}
                    variant='outlined-free'
                    rowHeight={100}
                    header={(
                        <WalletHeader onSearch={query => setQuery(query)}/>
                    )}
                    columns={columns}
                    data={rows}
                    content={{renderContent: (params) => (
                        <ChainData chain={params.data} wallets={filteredWallets}/>
                    )}}
                />
            )}
        </>
    ); 
};

export default Wallets;