import { getGetUsersQueryKey, useDeleteUser } from "@api/generated";
import { Title, Button, Modal, Text } from "@components/common";
import styles from './DeleteUser.module.css';
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from '@tanstack/react-query';

const DeleteUser = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate: deleteUser, isPending } = useDeleteUser({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetUsersQueryKey() });
            navigate("/users");
        },
    } });

    const handleDeleteUser = () => {
        if (userId) deleteUser({ userId: userId });
    };

    return (
        <Modal className={styles.card}>
            <Title underlined>Delete User</Title>
            <div className={styles.content}>
                <Text  className={styles.warning}>Are you sure you want to delete this user with id: "{userId}"?</Text>
                <Button
                    disabled={isPending}
                    className={styles.submitBtn}
                    type="button"
                    variant="contained"
                    onClick={() => handleDeleteUser()}
                    >
                    Delete
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteUser;