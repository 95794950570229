import { getGetWalletsQueryKey, useDeleteWallet } from "@api/generated";
import { Title, Button, Modal, Text } from "@components/common";
import styles from './DeleteWallet.module.css';
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from '@tanstack/react-query';

const DeleteWallet = () => {
    const { walletId } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate: deleteWallet, isPending } = useDeleteWallet({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetWalletsQueryKey() });
            navigate("/wallets");
        },
    } });

    const handleDeleteWallet = () => {
        if (walletId) deleteWallet({ walletId: walletId });
    };

    return (
        <Modal className={styles.card}>
            <Title underlined>Delete Wallet</Title>
            <div className={styles.content}>
                <Text  className={styles.warning}>Are you sure you want to delete this wallet with id: "{walletId}"?</Text>
                <Button
                    disabled={isPending}
                    className={styles.submitBtn}
                    type="button"
                    variant="contained"
                    onClick={() => handleDeleteWallet()}
                    >
                    Delete
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteWallet;