/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * WalletApi
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddWalletRequestBodySchema,
  DeleteWallet200,
  EditWalletBody,
  GetWalletsParams,
  PostWalletsWalletIdSign200,
  SignatureRequestSchema,
  WalletSchema,
  WalletTokenSchema
} from '.././schemas'
import { customInstance } from '../../api';
import type { ErrorType } from '../../api';



/**
 * @summary Lists or finds the wallets
 */
export const getWallets = (
    params?: GetWalletsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<WalletSchema[]>(
      {url: `/wallets`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetWalletsQueryKey = (params?: GetWalletsParams,) => {
    return [`/wallets`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWalletsQueryOptions = <TData = Awaited<ReturnType<typeof getWallets>>, TError = ErrorType<void>>(params?: GetWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWalletsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWallets>>> = ({ signal }) => getWallets(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWalletsQueryResult = NonNullable<Awaited<ReturnType<typeof getWallets>>>
export type GetWalletsQueryError = ErrorType<void>


export function useGetWallets<TData = Awaited<ReturnType<typeof getWallets>>, TError = ErrorType<void>>(
 params: undefined |  GetWalletsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWallets>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWallets<TData = Awaited<ReturnType<typeof getWallets>>, TError = ErrorType<void>>(
 params?: GetWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWallets>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWallets<TData = Awaited<ReturnType<typeof getWallets>>, TError = ErrorType<void>>(
 params?: GetWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists or finds the wallets
 */

export function useGetWallets<TData = Awaited<ReturnType<typeof getWallets>>, TError = ErrorType<void>>(
 params?: GetWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallets>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWalletsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Creates a new wallet
 */
export const createWallet = (
    addWalletRequestBodySchema: AddWalletRequestBodySchema,
 ) => {
      
      
      return customInstance<WalletSchema>(
      {url: `/wallets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addWalletRequestBodySchema
    },
      );
    }
  


export const getCreateWalletMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWallet>>, TError,{data: AddWalletRequestBodySchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createWallet>>, TError,{data: AddWalletRequestBodySchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWallet>>, {data: AddWalletRequestBodySchema}> = (props) => {
          const {data} = props ?? {};

          return  createWallet(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateWalletMutationResult = NonNullable<Awaited<ReturnType<typeof createWallet>>>
    export type CreateWalletMutationBody = AddWalletRequestBodySchema
    export type CreateWalletMutationError = ErrorType<void>

    /**
 * @summary Creates a new wallet
 */
export const useCreateWallet = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWallet>>, TError,{data: AddWalletRequestBodySchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createWallet>>,
        TError,
        {data: AddWalletRequestBodySchema},
        TContext
      > => {

      const mutationOptions = getCreateWalletMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns details about a specific wallet
 */
export const getWallet = (
    walletId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<WalletSchema>(
      {url: `/wallets/${walletId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetWalletQueryKey = (walletId: string,) => {
    return [`/wallets/${walletId}`] as const;
    }

    
export const getGetWalletQueryOptions = <TData = Awaited<ReturnType<typeof getWallet>>, TError = ErrorType<void>>(walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWalletQueryKey(walletId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWallet>>> = ({ signal }) => getWallet(walletId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(walletId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getWallet>>>
export type GetWalletQueryError = ErrorType<void>


export function useGetWallet<TData = Awaited<ReturnType<typeof getWallet>>, TError = ErrorType<void>>(
 walletId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWallet>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWallet<TData = Awaited<ReturnType<typeof getWallet>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWallet>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWallet<TData = Awaited<ReturnType<typeof getWallet>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns details about a specific wallet
 */

export function useGetWallet<TData = Awaited<ReturnType<typeof getWallet>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWallet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWalletQueryOptions(walletId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates the information about a wallet.
 */
export const editWallet = (
    walletId: string,
    editWalletBody: EditWalletBody,
 ) => {
      
      
      return customInstance<WalletSchema>(
      {url: `/wallets/${walletId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: editWalletBody
    },
      );
    }
  


export const getEditWalletMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editWallet>>, TError,{walletId: string;data: EditWalletBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof editWallet>>, TError,{walletId: string;data: EditWalletBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editWallet>>, {walletId: string;data: EditWalletBody}> = (props) => {
          const {walletId,data} = props ?? {};

          return  editWallet(walletId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditWalletMutationResult = NonNullable<Awaited<ReturnType<typeof editWallet>>>
    export type EditWalletMutationBody = EditWalletBody
    export type EditWalletMutationError = ErrorType<void>

    /**
 * @summary Updates the information about a wallet.
 */
export const useEditWallet = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editWallet>>, TError,{walletId: string;data: EditWalletBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof editWallet>>,
        TError,
        {walletId: string;data: EditWalletBody},
        TContext
      > => {

      const mutationOptions = getEditWalletMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Inactivates a wallet, it becomes unusable by users. The wallet can be activated again with a PATCH command.
 */
export const deleteWallet = (
    walletId: string,
 ) => {
      
      
      return customInstance<DeleteWallet200>(
      {url: `/wallets/${walletId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteWalletMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWallet>>, TError,{walletId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteWallet>>, TError,{walletId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWallet>>, {walletId: string}> = (props) => {
          const {walletId} = props ?? {};

          return  deleteWallet(walletId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWalletMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWallet>>>
    
    export type DeleteWalletMutationError = ErrorType<void>

    /**
 * @summary Inactivates a wallet, it becomes unusable by users. The wallet can be activated again with a PATCH command.
 */
export const useDeleteWallet = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWallet>>, TError,{walletId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWallet>>,
        TError,
        {walletId: string},
        TContext
      > => {

      const mutationOptions = getDeleteWalletMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns tokens on the wallet
 */
export const getWalletTokens = (
    walletId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<WalletTokenSchema[]>(
      {url: `/wallets/${walletId}/tokens`, method: 'GET', signal
    },
      );
    }
  

export const getGetWalletTokensQueryKey = (walletId: string,) => {
    return [`/wallets/${walletId}/tokens`] as const;
    }

    
export const getGetWalletTokensQueryOptions = <TData = Awaited<ReturnType<typeof getWalletTokens>>, TError = ErrorType<void>>(walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletTokens>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWalletTokensQueryKey(walletId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWalletTokens>>> = ({ signal }) => getWalletTokens(walletId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(walletId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWalletTokens>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWalletTokensQueryResult = NonNullable<Awaited<ReturnType<typeof getWalletTokens>>>
export type GetWalletTokensQueryError = ErrorType<void>


export function useGetWalletTokens<TData = Awaited<ReturnType<typeof getWalletTokens>>, TError = ErrorType<void>>(
 walletId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletTokens>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWalletTokens>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWalletTokens<TData = Awaited<ReturnType<typeof getWalletTokens>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletTokens>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWalletTokens>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWalletTokens<TData = Awaited<ReturnType<typeof getWalletTokens>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletTokens>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns tokens on the wallet
 */

export function useGetWalletTokens<TData = Awaited<ReturnType<typeof getWalletTokens>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletTokens>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWalletTokensQueryOptions(walletId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Signs a transaction
 */
export const postWalletsWalletIdSign = (
    walletId: string,
    signatureRequestSchema: SignatureRequestSchema,
 ) => {
      
      
      return customInstance<PostWalletsWalletIdSign200>(
      {url: `/wallets/${walletId}/sign`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: signatureRequestSchema
    },
      );
    }
  


export const getPostWalletsWalletIdSignMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWalletsWalletIdSign>>, TError,{walletId: string;data: SignatureRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postWalletsWalletIdSign>>, TError,{walletId: string;data: SignatureRequestSchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWalletsWalletIdSign>>, {walletId: string;data: SignatureRequestSchema}> = (props) => {
          const {walletId,data} = props ?? {};

          return  postWalletsWalletIdSign(walletId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostWalletsWalletIdSignMutationResult = NonNullable<Awaited<ReturnType<typeof postWalletsWalletIdSign>>>
    export type PostWalletsWalletIdSignMutationBody = SignatureRequestSchema
    export type PostWalletsWalletIdSignMutationError = ErrorType<void>

    /**
 * @summary Signs a transaction
 */
export const usePostWalletsWalletIdSign = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWalletsWalletIdSign>>, TError,{walletId: string;data: SignatureRequestSchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postWalletsWalletIdSign>>,
        TError,
        {walletId: string;data: SignatureRequestSchema},
        TContext
      > => {

      const mutationOptions = getPostWalletsWalletIdSignMutationOptions(options);

      return useMutation(mutationOptions);
    }
    