import { useMemo } from "react";
import { getGetWalletsQueryKey, useEditWallet, WalletSchema } from "@api/generated";
import { BoldText, IconButton, List, ListColumnType, Text } from "@components/common";
import styles from './ChainData.module.css';
import { formatCurrency } from "@utils/transformers";
import { Chain, Wallet } from "../Wallets";
import { CheckIcon, ErrorIcon } from "@components/icons";
import WalletData from "./WalletData";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

interface ChainDataProps {
    chain?: Chain;
    wallets?: WalletSchema[];
}

const ChainData = ({ chain, wallets }: ChainDataProps) => {
    
    const queryClient = useQueryClient();
    const { mutate: editWallet } = useEditWallet({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetWalletsQueryKey() });
        },
    } });

    const rows: Wallet[] = useMemo(() => {
        if (!wallets || !chain) return [];
        return wallets
            .filter(wallet => wallet.chain.chain_id === chain.chain_id)
            .map(row =>
            ({
                id: row.wallet_id,
                ...row
            }))
        }, [wallets, chain]
    );

    const columns: ListColumnType<Wallet>[] = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                width: '30%',
            },
            {
                field: 'address',
                headerName: 'Address',
                width: '35%',
                valueFormatter: (params) => params.data?.address.slice(0, 10) + " ...... " + params.data?.address.slice(-10)
            },
            {
                field: 'id',
                headerName: '',
                width: '15%',
                textAlign: 'right',
                renderCell: (params) => {
                    return (
                        <IconButton 
                            className={classNames({
                                [styles.deactivateWalletBtn]: params.data?.state === 'active',
                                [styles.activateWalletBtn]: params.data?.state === 'inactive'
                            })} 
                            onClick={() => { if (params.data) togleWalletState(params.data); }}
                        >
                            {params.data?.state === 'active' ? <CheckIcon/> : <ErrorIcon/>}
                        </IconButton>
                    )
                }
            },
            {
                field: 'balance',
                headerName: '',
                width: '15%',
                textAlign: 'right',
                renderCell: (params) => {
                    if (params.data?.balance) return (<BoldText>{formatCurrency(params.data.balance)}</BoldText>);
                    return null;
                }
            },
        ], []
    );

    const togleWalletState = (wallet: Wallet) => {
        editWallet(
        {
            walletId: wallet.wallet_id, 
            data: { 
                name: wallet.name, 
                state: wallet.state === 'active' ? 'inactive' : 'active'
            }
        });
    }

    return (
        <div className={styles.chainData}>
            <div>
                <Text className={styles.label}>Wallets</Text>
                <List
                    type="secondary"
                    className={styles.list}
                    variant="outlined-free"
                    columns={columns}
                    data={rows}
                    rowHeight={100}
                    size="small"
                    content={{renderContent: (params) => (
                        <WalletData wallet={params.data}/>
                    )}}
                />
            </div>
        </div>
    );
};

export default ChainData;