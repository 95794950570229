import { useMemo } from "react";
import { Navigate, Outlet, useRoutes } from "react-router";
import Layout from "../Layout/Layout";
import { authenticatedRoutes, unauthenticatedRoutes } from "../../routes";
import { BaseRoute, RoutePremissionLevelEnum } from "../../RouteInterfaces";
import { useAuthContext } from "@api/auth/AuthContext";

const Routes = () => {

    const { isAuthenticated } = useAuthContext();

    /*
    const filterRoutes = (routes: BaseRoute[], user: User)  => {
        return routes.filter(route => {
            if (route.permissionLevel === RoutePremissionLevelEnum.Admin)
                return user.admin || user.superAdmin;
            if (route.permissionLevel === RoutePremissionLevelEnum.SuperAdmin)
                return user.superAdmin;
            return true;
        });
    }
    */

    const routes = useMemo(() => {
        if (isAuthenticated) {
            //const routesForUser = filterRoutes(authenticatedRoutes, user);
            const routesForUser = authenticatedRoutes;
            return [
                {
                    caseSensitive: true,
                    path: '',
                    element: <Navigate to={'wallets'} replace/>,
                },
                {
                    caseSensitive: true,
                    path: '/*',
                    element: <Navigate to={'wallets'} replace/>,
                },
                {
                    caseSensitive: true,
                    path: '/',
                    element: <Layout routes={routesForUser}/>,
                    children: routesForUser
                },
            ];
        }

        return [
            {
                caseSensitive: true,
                path: '',
                element: <Navigate to={'login'} replace/>,
            },
            {
                caseSensitive: true,
                path: '/*',
                element: <Navigate to={'login'} replace/>,
            },
            {
                caseSensitive: true,
                path: '/',
                element: <Outlet />,
                children: unauthenticatedRoutes,
            },
        ];
    }, [isAuthenticated])

    const elements = useRoutes(routes);

    return elements;
}

export default Routes;