/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * WalletApi
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddUserRequestBodySchema,
  DeleteUser200,
  EditUser200,
  GetUsersSelf200,
  PatchUserRequestBodySchema,
  UserSchema
} from '.././schemas'
import { customInstance } from '../../api';
import type { ErrorType } from '../../api';



/**
 * @summary Lists the users
 */
export const getUsers = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<UserSchema[]>(
      {url: `/users`, method: 'GET', signal
    },
      );
    }
  

export const getGetUsersQueryKey = () => {
    return [`/users`] as const;
    }

    
export const getGetUsersQueryOptions = <TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({ signal }) => getUsers(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getUsers>>>
export type GetUsersQueryError = ErrorType<void>


export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsers>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsers>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Lists the users
 */

export function useGetUsers<TData = Awaited<ReturnType<typeof getUsers>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsers>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Create a new user
 */
export const createUser = (
    addUserRequestBodySchema: AddUserRequestBodySchema,
 ) => {
      
      
      return customInstance<UserSchema>(
      {url: `/users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addUserRequestBodySchema
    },
      );
    }
  


export const getCreateUserMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{data: AddUserRequestBodySchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{data: AddUserRequestBodySchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUser>>, {data: AddUserRequestBodySchema}> = (props) => {
          const {data} = props ?? {};

          return  createUser(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
    export type CreateUserMutationBody = AddUserRequestBodySchema
    export type CreateUserMutationError = ErrorType<void>

    /**
 * @summary Create a new user
 */
export const useCreateUser = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{data: AddUserRequestBodySchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createUser>>,
        TError,
        {data: AddUserRequestBodySchema},
        TContext
      > => {

      const mutationOptions = getCreateUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns details about a specific user
 */
export const getUser = (
    userId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<UserSchema>(
      {url: `/users/${userId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetUserQueryKey = (userId: string,) => {
    return [`/users/${userId}`] as const;
    }

    
export const getGetUserQueryOptions = <TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<void>>(userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(userId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = ErrorType<void>


export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<void>>(
 userId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUser>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUser>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns details about a specific user
 */

export function useGetUser<TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<void>>(
 userId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates the user name
 */
export const editUser = (
    userId: string,
    patchUserRequestBodySchema: PatchUserRequestBodySchema,
 ) => {
      
      
      return customInstance<EditUser200>(
      {url: `/users/${userId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchUserRequestBodySchema
    },
      );
    }
  


export const getEditUserMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editUser>>, TError,{userId: string;data: PatchUserRequestBodySchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof editUser>>, TError,{userId: string;data: PatchUserRequestBodySchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editUser>>, {userId: string;data: PatchUserRequestBodySchema}> = (props) => {
          const {userId,data} = props ?? {};

          return  editUser(userId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EditUserMutationResult = NonNullable<Awaited<ReturnType<typeof editUser>>>
    export type EditUserMutationBody = PatchUserRequestBodySchema
    export type EditUserMutationError = ErrorType<void>

    /**
 * @summary Updates the user name
 */
export const useEditUser = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editUser>>, TError,{userId: string;data: PatchUserRequestBodySchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof editUser>>,
        TError,
        {userId: string;data: PatchUserRequestBodySchema},
        TContext
      > => {

      const mutationOptions = getEditUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes the user from the profile
 */
export const deleteUser = (
    userId: string,
 ) => {
      
      
      return customInstance<DeleteUser200>(
      {url: `/users/${userId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteUserMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{userId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteUser(userId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
    
    export type DeleteUserMutationError = ErrorType<void>

    /**
 * @summary Removes the user from the profile
 */
export const useDeleteUser = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{userId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUser>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getDeleteUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns the name, email & organization info of the current user
 */
export const getUsersSelf = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetUsersSelf200>(
      {url: `/users/self`, method: 'GET', signal
    },
      );
    }
  

export const getGetUsersSelfQueryKey = () => {
    return [`/users/self`] as const;
    }

    
export const getGetUsersSelfQueryOptions = <TData = Awaited<ReturnType<typeof getUsersSelf>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersSelf>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersSelfQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersSelf>>> = ({ signal }) => getUsersSelf(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersSelf>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersSelfQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersSelf>>>
export type GetUsersSelfQueryError = ErrorType<void>


export function useGetUsersSelf<TData = Awaited<ReturnType<typeof getUsersSelf>>, TError = ErrorType<void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersSelf>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsersSelf>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsersSelf<TData = Awaited<ReturnType<typeof getUsersSelf>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersSelf>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUsersSelf>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUsersSelf<TData = Awaited<ReturnType<typeof getUsersSelf>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersSelf>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns the name, email & organization info of the current user
 */

export function useGetUsersSelf<TData = Awaited<ReturnType<typeof getUsersSelf>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersSelf>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUsersSelfQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



