import { NavigationButton, Title, SearchBar, SelectField, Selector } from "@components/common";
import { PlusIcon } from "@components/icons";
import styles from './WalletHeader.module.css';
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { WalletSchema } from "@api/generated";

export interface WalletQuery {
    field: keyof WalletSchema;
    value: string;
}

interface Props {
    onSearch?: (query: WalletQuery) => void;
}

const WalletHeader = ({ onSearch }: Props) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const query = useMemo(() => {
        const queryParam = searchParams.entries().next().value;
        if (queryParam) {
            return {field: queryParam[0] as keyof WalletSchema, value: queryParam[1] };
        }
        return undefined;
    }, [searchParams]);
    
    const handleSearch = (search: string) => {
        if (!query) return;
        setSearchParams({[query.field]: search});
    }

    const selectField = (field: string) => {
        const key = field as keyof WalletSchema;
        if (key) {
            setSearchParams({[key]: query?.value});
        }
        else {
            setSearchParams();
        }
    }

    useEffect(() => {
        if (query) {
            if (query.field && onSearch) {
                onSearch(query);
            }
        }
    }, [query]);

    useEffect(() => {
        setSearchParams({name: ""})
    }, []);

    const queryOptions = [
        {
            label: 'name',
            value: 'name'
        },
        {
            label: 'wallet id',
            value: 'wallet_id'
        },
        {
            label: 'address',
            value: 'address'
        }
    ];

    return (
        <div className={styles.headerContainer}>
            <Title className={styles.title}>Wallets</Title>
            <div className={styles.actionContainer}>
                <div className={styles.searchContainer}>
                    <SearchBar
                        className={styles.searchBar} 
                        onChange={handleSearch}
                        onSearch={handleSearch}
                        value={query?.value}
                        placeholder={query ? `Search by ${queryOptions.find(x => x.value === query.field)?.label}` : "Select a search field"}
                    />
                    <Selector
                        variant='shadow'
                        options={queryOptions} 
                        onSelect={selectField} 
                        btnClassName={styles.selectBtn} 
                        boxClassName={styles.selectBox}
                        containerClassName={styles.selectContainer}
                        value={query?.field}
                    />
                </div>
                <NavigationButton
                    startIcon={<PlusIcon/>}
                    pathTo="add"
                    className={styles.addWalletBtn}
                >
                    Add wallet
                </NavigationButton>
            </div>
        </div>
    ); 
};

export default WalletHeader;