import { GroupSchema } from "@api/generated"
import { AptosIcon, BotIcon, EthereumIcon, GroupIcon, RadixIcon, TraderIcon } from "@components/icons"
import { number } from "yup"

export const getChainIcon = (chainName: string) => {
    if(chainName === "EVM") return <EthereumIcon/>
    if(chainName === "Aptos") return <AptosIcon/>
    if(chainName === "Radix") return <RadixIcon/>
    return <>{chainName}</>
}

export const getUserIcon = (userType: string) => {
    if(userType === "BOT") return <BotIcon/>
    if(userType === "TRADER") return <TraderIcon/>
    if(userType === "GROUP") return <GroupIcon/>
    return <></>
}

export const formatCurrency = (amount: number) => {
    return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'EUR'
    });
}

interface UserOrGroup {
    type: 'GROUP' | 'TRADER' | 'BOT'; 
    name: string; 
    id: string;
}
export const transformGroups = (groups: GroupSchema[]): UserOrGroup[] => {
    var result: UserOrGroup[] = [];
    groups.forEach(group => {
        if (group.group_id === '-') {
            result.push(...group.members.map(member => ({type: member.type, name: member.name, id: member.user_id})));
        }
        else {
            result.push({type: 'GROUP', name: group.name, id: group.group_id});
        }
    });
    return result;
}