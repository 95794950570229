import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/components/Routes/Routes';
import { AuthContextProvider } from '@api/auth/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';


function App() {

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false,
            staleTime: 240000,
          },
        },
      })
  );
  
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider> {/* The AuthContext should be nested within the QueryClient in order to call auth endpoints and use interceptor to logout user on a 401 response */}
          <Routes/>
        </AuthContextProvider> 
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
