import { getGetUserQueryKey, getGetUsersQueryKey, useCreateUser, useEditUser, useGetUser } from "@api/generated";
import { Title, Button, Modal, RadioGroup, EmptyState, Loader } from "@components/common";
import styles from './EditUser.module.css';
import { TextField } from "@components/common";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from "react";

interface EditUserForm {
    name: string; 
    type?: string;
    public_key?: string;
    email?: string;
}

const EditUser = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate: editUser, isPending } = useEditUser({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetUsersQueryKey() });
            await queryClient.invalidateQueries({ queryKey: getGetUserQueryKey(userId ?? "") });
            navigate("/users");
        },
    } });

    const { data: user, isLoading } = useGetUser(userId ?? "");

    const methods = useForm<EditUserForm>({
        resolver: yupResolver(
            Yup.object({
                name: Yup.string().trim().required("Name is required"),
                email: Yup.string().trim().email().test('required-as-trader', 'Email is required for trader', (value, context) => context.schema['type'] !== 'TRADER' || value?.trim() !== ''),
                public_key: Yup.string().trim().test('required-as-bot', 'Public key is required for bot', (value, context) => context.schema['type'] !== 'BOT' || value?.trim() !== ''),
                type: Yup.string().trim(),
            })
        ),
        defaultValues: { 
            name: '', 
            type: 'TRADER',
            email: '',
            public_key: ''
        },
        mode: 'onChange',
    });

    const onSubmit = (values: EditUserForm) => {
        if (!userId) return;
        if (values.type === 'TRADER') {
            editUser({ userId: userId, data: { name: values.name, email: values.email! } });
        }
        if (values.type === 'BOT') {
            editUser({ userId: userId, data: { name: values.name, public_key: values.public_key! } });
        }
    };

    useEffect(() => {
        if (user) {
            methods.reset({
                name: user.name,
                email: user.email,
                type: user.type,
                public_key: user.public_key
            })
        }
    }, [user, methods]);

    if (isLoading) {
        return (
            <Loader/>
        );
    }
    
    if (!user) {
        return (
            <Modal className={styles.card}>
                <EmptyState size="medium" title={"No user found with this id"}/>
            </Modal>
        );
    }

    return (
        <Modal className={styles.card}>
            <FormProvider {...methods}>
                <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                <Title underlined>Edit User</Title>
                    <div className={styles.formContent}>
                        <RadioGroup 
                            disabled
                            className={styles.radioInputContainer} 
                            name='type' 
                            options={[{value: 'TRADER', label: 'Trader'}, {value: 'BOT', label: 'Bot'}]}
                            />
                        <TextField variant='underlined' className={styles.textInput} placeholder="Name" name="name"/>
                        {user.type === 'TRADER' && 
                            <TextField variant='underlined' className={styles.textInput} placeholder="Email" name="email" value={user.email}/>
                        }
                        {user.type === 'BOT' && 
                            <TextField variant='underlined' className={styles.textInput} placeholder="Public key" name="public_key" value={user.public_key}/>
                        }
                        <Button
                            disabled={isPending}
                            className={styles.submitBtn}
                            type="submit"
                            variant="contained"
                            >
                            Submit
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default EditUser;