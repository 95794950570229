import { BaseRoute, RoutePremissionLevelEnum } from "./RouteInterfaces";
import Login from "@pages/Login/Login";
import { DashBoardIcon, GroupIcon, PolicyIcon, UserIcon, WalletIcon } from "@components/icons";
import Users from "@pages/Users/Users";
import Wallets from "@pages/Wallets/Wallets";
import Groups from "@pages/Groups/Groups";
import Policies from "@pages/Policies/Policies";
import Analytics from "@pages/Analytics/Analytics";
import AddWallet from "@pages/Wallets/AddWallet";
import AddUser from "@pages/Users/AddUser";
import AddGroup from "@pages/Groups/AddGroup";
import EditUser from "@pages/Users/EditUser";
import DeleteUser from "@pages/Users/DeleteUser";
import DeleteWallet from "@pages/Wallets/DeleteWallet";
import AddUserToWallet from "@pages/Policies/AddUserToWallet";
import EditGroup from "@pages/Groups/EditGroup";
import DeleteGroup from "@pages/Groups/DeleteGroup";
import AddUserToGroup from "@pages/Groups/AddUserToGroup";

const analyticsRoutes: Array<BaseRoute> = [
    {
        name: 'Analytics',
        path: 'analytics',
        element: <Analytics/>,
        hidden: true,
        icon: DashBoardIcon(),
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
]

const walletRoutes: Array<BaseRoute> = [
    {
        name: 'Wallets',
        path: 'wallets',
        element: <Wallets/>,
        hidden: false,
        icon: WalletIcon(),
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'AddWallet',
        path: 'wallets/add',
        element: <AddWallet/>,
        hidden: true,
        asModal: true,
        parent: '/wallets',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'DeleteWallet',
        path: 'wallets/:walletId/delete',
        element: <DeleteWallet/>,
        hidden: true,
        asModal: true,
        parent: '/wallets',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'AddUserToWallet',
        path: 'wallets/:walletId/adduser',
        element: <AddUserToWallet/>,
        hidden: true,
        asModal: true,
        parent: '/wallets',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
]

const userRoutes: Array<BaseRoute> = [
    {
        name: 'Users',
        path: 'users',
        element: <Users/>,
        hidden: false,
        icon: UserIcon(),
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'AddUser',
        path: 'users/add',
        element: <AddUser/>,
        hidden: true,
        asModal: true,
        parent: '/users',
        permissionLevel: RoutePremissionLevelEnum.None,
        //featureFlag:
    },
    {
        name: 'EditUser',
        path: 'users/:userId/edit',
        element: <EditUser/>,
        hidden: true,
        asModal: true,
        parent: '/users',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'DeleteUser',
        path: 'users/:userId/delete',
        element: <DeleteUser/>,
        hidden: true,
        asModal: true,
        parent: '/users',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
]

const groupRoutes: Array<BaseRoute> = [
    {
        name: 'Groups',
        path: 'groups',
        element: <Groups/>,
        hidden: false,
        icon: GroupIcon(),
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'AddGroup',
        path: 'groups/add',
        element: <AddGroup/>,
        hidden: true,
        asModal: true,
        parent: '/groups',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'EditGroup',
        path: 'groups/:groupId/edit',
        element: <EditGroup/>,
        hidden: true,
        asModal: true,
        parent: '/groups',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'DeleteGroup',
        path: 'groups/:groupId/delete',
        element: <DeleteGroup/>,
        hidden: true,
        asModal: true,
        parent: '/groups',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
    {
        name: 'AddUserToGroup',
        path: 'groups/:groupId/adduser',
        element: <AddUserToGroup/>,
        hidden: true,
        asModal: true,
        parent: '/groups',
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
]

const policyRoutes: Array<BaseRoute> = [
    {
        name: 'Policies',
        path: 'policies',
        element: <Policies/>,
        hidden: true,
        icon: PolicyIcon(),
        permissionLevel: RoutePremissionLevelEnum.None
        //featureFlag:
    },
]

export const authenticatedRoutes: Array<BaseRoute> = [
    ...analyticsRoutes,
    ...walletRoutes,
    ...userRoutes,
    ...groupRoutes,
    ...policyRoutes
]

export const unauthenticatedRoutes: Array<BaseRoute> = [
    {
        name: 'Login',
        path: 'login',
        element: <Login/>,
        hidden: true,
        permissionLevel: RoutePremissionLevelEnum.None
    },
    {
        name: 'Oops',
        path: 'oops',
        //element: Oops(),
        hidden: true,
        permissionLevel: RoutePremissionLevelEnum.None
    },
]