/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * WalletApi
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  DeleteResponseBodySchema,
  GetGroupPolicies200,
  GetUserWalletsParams,
  GroupSchema,
  PatchPolicyRequestBodySchema,
  PatchResponseBodySchema,
  WalletSchema
} from '.././schemas'
import { customInstance } from '../../api';
import type { ErrorType } from '../../api';



/**
 * @summary Returns all wallets the user has access to though individual or group relations
 */
export const getUserWallets = (
    userId: string,
    params?: GetUserWalletsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<WalletSchema[]>(
      {url: `/policies/users/${userId}`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getGetUserWalletsQueryKey = (userId: string,
    params?: GetUserWalletsParams,) => {
    return [`/policies/users/${userId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUserWalletsQueryOptions = <TData = Awaited<ReturnType<typeof getUserWallets>>, TError = ErrorType<void>>(userId: string,
    params?: GetUserWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserWallets>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserWalletsQueryKey(userId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserWallets>>> = ({ signal }) => getUserWallets(userId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserWallets>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserWalletsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserWallets>>>
export type GetUserWalletsQueryError = ErrorType<void>


export function useGetUserWallets<TData = Awaited<ReturnType<typeof getUserWallets>>, TError = ErrorType<void>>(
 userId: string,
    params: undefined |  GetUserWalletsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserWallets>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserWallets>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserWallets<TData = Awaited<ReturnType<typeof getUserWallets>>, TError = ErrorType<void>>(
 userId: string,
    params?: GetUserWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserWallets>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUserWallets>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetUserWallets<TData = Awaited<ReturnType<typeof getUserWallets>>, TError = ErrorType<void>>(
 userId: string,
    params?: GetUserWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserWallets>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all wallets the user has access to though individual or group relations
 */

export function useGetUserWallets<TData = Awaited<ReturnType<typeof getUserWallets>>, TError = ErrorType<void>>(
 userId: string,
    params?: GetUserWalletsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserWallets>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserWalletsQueryOptions(userId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates which wallets the user has direct access to
 */
export const patchPoliciesUsersUserId = (
    userId: string,
    patchPolicyRequestBodySchema: PatchPolicyRequestBodySchema,
 ) => {
      
      
      return customInstance<PatchResponseBodySchema>(
      {url: `/policies/users/${userId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchPolicyRequestBodySchema
    },
      );
    }
  


export const getPatchPoliciesUsersUserIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchPoliciesUsersUserId>>, TError,{userId: string;data: PatchPolicyRequestBodySchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof patchPoliciesUsersUserId>>, TError,{userId: string;data: PatchPolicyRequestBodySchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchPoliciesUsersUserId>>, {userId: string;data: PatchPolicyRequestBodySchema}> = (props) => {
          const {userId,data} = props ?? {};

          return  patchPoliciesUsersUserId(userId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchPoliciesUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchPoliciesUsersUserId>>>
    export type PatchPoliciesUsersUserIdMutationBody = PatchPolicyRequestBodySchema
    export type PatchPoliciesUsersUserIdMutationError = ErrorType<void>

    /**
 * @summary Updates which wallets the user has direct access to
 */
export const usePatchPoliciesUsersUserId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchPoliciesUsersUserId>>, TError,{userId: string;data: PatchPolicyRequestBodySchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof patchPoliciesUsersUserId>>,
        TError,
        {userId: string;data: PatchPolicyRequestBodySchema},
        TContext
      > => {

      const mutationOptions = getPatchPoliciesUsersUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Revokes direct access for the user
 */
export const deletePoliciesUsersUserId = (
    userId: string,
 ) => {
      
      
      return customInstance<DeleteResponseBodySchema>(
      {url: `/policies/users/${userId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeletePoliciesUsersUserIdMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePoliciesUsersUserId>>, TError,{userId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deletePoliciesUsersUserId>>, TError,{userId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePoliciesUsersUserId>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deletePoliciesUsersUserId(userId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePoliciesUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deletePoliciesUsersUserId>>>
    
    export type DeletePoliciesUsersUserIdMutationError = ErrorType<void>

    /**
 * @summary Revokes direct access for the user
 */
export const useDeletePoliciesUsersUserId = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePoliciesUsersUserId>>, TError,{userId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof deletePoliciesUsersUserId>>,
        TError,
        {userId: string},
        TContext
      > => {

      const mutationOptions = getDeletePoliciesUsersUserIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns all wallets the group has access to
 */
export const getGroupPolicies = (
    groupId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetGroupPolicies200>(
      {url: `/policies/groups/${groupId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetGroupPoliciesQueryKey = (groupId: string,) => {
    return [`/policies/groups/${groupId}`] as const;
    }

    
export const getGetGroupPoliciesQueryOptions = <TData = Awaited<ReturnType<typeof getGroupPolicies>>, TError = ErrorType<void>>(groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupPolicies>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGroupPoliciesQueryKey(groupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupPolicies>>> = ({ signal }) => getGroupPolicies(groupId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(groupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGroupPolicies>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGroupPoliciesQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupPolicies>>>
export type GetGroupPoliciesQueryError = ErrorType<void>


export function useGetGroupPolicies<TData = Awaited<ReturnType<typeof getGroupPolicies>>, TError = ErrorType<void>>(
 groupId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupPolicies>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroupPolicies>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroupPolicies<TData = Awaited<ReturnType<typeof getGroupPolicies>>, TError = ErrorType<void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupPolicies>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGroupPolicies>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGroupPolicies<TData = Awaited<ReturnType<typeof getGroupPolicies>>, TError = ErrorType<void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupPolicies>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all wallets the group has access to
 */

export function useGetGroupPolicies<TData = Awaited<ReturnType<typeof getGroupPolicies>>, TError = ErrorType<void>>(
 groupId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGroupPolicies>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetGroupPoliciesQueryOptions(groupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates which wallets the group has access to
 */
export const updateGroupAccess = (
    groupId: string,
    patchPolicyRequestBodySchema: PatchPolicyRequestBodySchema,
 ) => {
      
      
      return customInstance<PatchResponseBodySchema>(
      {url: `/policies/groups/${groupId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchPolicyRequestBodySchema
    },
      );
    }
  


export const getUpdateGroupAccessMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGroupAccess>>, TError,{groupId: string;data: PatchPolicyRequestBodySchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateGroupAccess>>, TError,{groupId: string;data: PatchPolicyRequestBodySchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGroupAccess>>, {groupId: string;data: PatchPolicyRequestBodySchema}> = (props) => {
          const {groupId,data} = props ?? {};

          return  updateGroupAccess(groupId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateGroupAccessMutationResult = NonNullable<Awaited<ReturnType<typeof updateGroupAccess>>>
    export type UpdateGroupAccessMutationBody = PatchPolicyRequestBodySchema
    export type UpdateGroupAccessMutationError = ErrorType<void>

    /**
 * @summary Updates which wallets the group has access to
 */
export const useUpdateGroupAccess = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGroupAccess>>, TError,{groupId: string;data: PatchPolicyRequestBodySchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateGroupAccess>>,
        TError,
        {groupId: string;data: PatchPolicyRequestBodySchema},
        TContext
      > => {

      const mutationOptions = getUpdateGroupAccessMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Revokes all access for the group
 */
export const revokeAllGroupAccess = (
    groupId: string,
 ) => {
      
      
      return customInstance<DeleteResponseBodySchema>(
      {url: `/policies/groups/${groupId}`, method: 'DELETE'
    },
      );
    }
  


export const getRevokeAllGroupAccessMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeAllGroupAccess>>, TError,{groupId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof revokeAllGroupAccess>>, TError,{groupId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeAllGroupAccess>>, {groupId: string}> = (props) => {
          const {groupId} = props ?? {};

          return  revokeAllGroupAccess(groupId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RevokeAllGroupAccessMutationResult = NonNullable<Awaited<ReturnType<typeof revokeAllGroupAccess>>>
    
    export type RevokeAllGroupAccessMutationError = ErrorType<void>

    /**
 * @summary Revokes all access for the group
 */
export const useRevokeAllGroupAccess = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeAllGroupAccess>>, TError,{groupId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof revokeAllGroupAccess>>,
        TError,
        {groupId: string},
        TContext
      > => {

      const mutationOptions = getRevokeAllGroupAccessMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns all users and groups that have access to the wallet
 */
export const getWalletPolicies = (
    walletId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GroupSchema[]>(
      {url: `/policies/wallets/${walletId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetWalletPoliciesQueryKey = (walletId: string,) => {
    return [`/policies/wallets/${walletId}`] as const;
    }

    
export const getGetWalletPoliciesQueryOptions = <TData = Awaited<ReturnType<typeof getWalletPolicies>>, TError = ErrorType<void>>(walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletPolicies>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWalletPoliciesQueryKey(walletId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWalletPolicies>>> = ({ signal }) => getWalletPolicies(walletId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(walletId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWalletPolicies>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWalletPoliciesQueryResult = NonNullable<Awaited<ReturnType<typeof getWalletPolicies>>>
export type GetWalletPoliciesQueryError = ErrorType<void>


export function useGetWalletPolicies<TData = Awaited<ReturnType<typeof getWalletPolicies>>, TError = ErrorType<void>>(
 walletId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletPolicies>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWalletPolicies>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWalletPolicies<TData = Awaited<ReturnType<typeof getWalletPolicies>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletPolicies>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getWalletPolicies>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetWalletPolicies<TData = Awaited<ReturnType<typeof getWalletPolicies>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletPolicies>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Returns all users and groups that have access to the wallet
 */

export function useGetWalletPolicies<TData = Awaited<ReturnType<typeof getWalletPolicies>>, TError = ErrorType<void>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWalletPolicies>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWalletPoliciesQueryOptions(walletId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates which users and groups have access to the wallet
 */
export const updateWalletAccess = (
    walletId: string,
    patchPolicyRequestBodySchema: PatchPolicyRequestBodySchema,
 ) => {
      
      
      return customInstance<PatchResponseBodySchema>(
      {url: `/policies/wallets/${walletId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchPolicyRequestBodySchema
    },
      );
    }
  


export const getUpdateWalletAccessMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWalletAccess>>, TError,{walletId: string;data: PatchPolicyRequestBodySchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateWalletAccess>>, TError,{walletId: string;data: PatchPolicyRequestBodySchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWalletAccess>>, {walletId: string;data: PatchPolicyRequestBodySchema}> = (props) => {
          const {walletId,data} = props ?? {};

          return  updateWalletAccess(walletId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateWalletAccessMutationResult = NonNullable<Awaited<ReturnType<typeof updateWalletAccess>>>
    export type UpdateWalletAccessMutationBody = PatchPolicyRequestBodySchema
    export type UpdateWalletAccessMutationError = ErrorType<void>

    /**
 * @summary Updates which users and groups have access to the wallet
 */
export const useUpdateWalletAccess = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWalletAccess>>, TError,{walletId: string;data: PatchPolicyRequestBodySchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof updateWalletAccess>>,
        TError,
        {walletId: string;data: PatchPolicyRequestBodySchema},
        TContext
      > => {

      const mutationOptions = getUpdateWalletAccessMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Revokes all access to the wallet
 */
export const revokeAllWalletAccess = (
    walletId: string,
 ) => {
      
      
      return customInstance<DeleteResponseBodySchema>(
      {url: `/policies/wallets/${walletId}`, method: 'DELETE'
    },
      );
    }
  


export const getRevokeAllWalletAccessMutationOptions = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeAllWalletAccess>>, TError,{walletId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof revokeAllWalletAccess>>, TError,{walletId: string}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeAllWalletAccess>>, {walletId: string}> = (props) => {
          const {walletId} = props ?? {};

          return  revokeAllWalletAccess(walletId,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RevokeAllWalletAccessMutationResult = NonNullable<Awaited<ReturnType<typeof revokeAllWalletAccess>>>
    
    export type RevokeAllWalletAccessMutationError = ErrorType<void>

    /**
 * @summary Revokes all access to the wallet
 */
export const useRevokeAllWalletAccess = <TError = ErrorType<void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeAllWalletAccess>>, TError,{walletId: string}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof revokeAllWalletAccess>>,
        TError,
        {walletId: string},
        TContext
      > => {

      const mutationOptions = getRevokeAllWalletAccessMutationOptions(options);

      return useMutation(mutationOptions);
    }
    