import { getGetUsersQueryKey, useCreateUser } from "@api/generated";
import { Title, Button, Modal, RadioGroup } from "@components/common";
import styles from './AddUser.module.css';
import { TextField } from "@components/common";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate } from "react-router";
import { useQueryClient } from '@tanstack/react-query';

interface AddUserForm {
    name: string; 
    type: string;
    public_key?: string;
    email?: string;
}

const AddUser = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate: createUser, isPending } = useCreateUser({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetUsersQueryKey() });
            navigate("/users");
        },
    } });

    const methods = useForm<AddUserForm>({
        resolver: yupResolver(
            Yup.object({
                name: Yup.string().trim().required("Name is required"),
                email: Yup.string().trim().email().test('required-as-trader', 'Email is required for trader', (value, context) => context.schema['type'] !== 'TRADER' || value?.trim() !== ''),
                public_key: Yup.string().trim().test('required-as-bot', 'Public key is required for bot', (value, context) => context.schema['type'] !== 'BOT' || value?.trim() !== ''),
                type: Yup.string().trim().required(),
            })
        ),
        defaultValues: { 
            name: '', 
            type: 'TRADER',
            email: ''
        },
        mode: 'onChange',
    });

    const userType = methods.watch('type') as string;

    const onSubmit = (values: AddUserForm) => {
        if (values.type === 'TRADER') {
            createUser({ data: { name: values.name, email: values.email!, type: values.type } });
        }
        if (values.type === 'BOT') {
            createUser({ data: { name: values.name, public_key: values.public_key!, type: values.type } });
        }
    };

    return (
        <Modal className={styles.card}>
            <FormProvider {...methods}>
                <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                <Title underlined>Add User</Title>
                    <div className={styles.formContent}>
                        <RadioGroup
                            className={styles.radioInputContainer} 
                            name='type' 
                            options={[{value: 'TRADER', label: 'Trader'}, {value: 'BOT', label: 'Bot'}]}
                            />
                        <TextField variant='underlined' className={styles.textInput} placeholder="Name" name="name"/>
                        {userType === 'TRADER' && <TextField variant='underlined' className={styles.textInput} placeholder="Email" name="email"/>}
                        {userType === 'BOT' && <TextField variant='underlined' className={styles.textInput} placeholder="Public key" name="public_key"/>}
                        <Button
                            disabled={isPending}
                            className={styles.submitBtn}
                            type="submit"
                            variant="contained"
                            >
                            Submit
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AddUser;